var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "MSDS 내부용" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("c-upload", {
                    attrs: {
                      attachInfo: _vm.attachInfo,
                      editable: _vm.editable,
                      label: "MSDS 내부용 첨부파일",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailForm",
            attrs: { title: "MSDS 업체용" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("c-upload", {
                    attrs: {
                      attachInfo: _vm.attachInfo2,
                      editable: _vm.editable,
                      label: "MSDS 업체용 첨부파일",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }