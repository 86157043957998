<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="MSDS 내부용" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload
              :attachInfo="attachInfo"
              :editable="editable"
              label="MSDS 내부용 첨부파일">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="MSDS 업체용" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12">
            <c-upload
              :attachInfo="attachInfo2"
              :editable="editable"
              label="MSDS 업체용 첨부파일">
            </c-upload>
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chem-msdsfile',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantName: '',
        plantCd: '',
        materialCd: '',
        materialName: '',
        isSaved: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'CHEM_MSDS',
        taskKey: '',
      },
      attachInfo2: {
        isSubmit: '',
        taskClassCd: 'CHEM_MSDS_VENDOR',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfo, 'taskKey', this.param.mdmChemMaterialId)
      this.$set(this.attachInfo2, 'taskKey', this.param.mdmChemMaterialId)
    },
  }
};
</script>